.intro {
  display: flex;
  justify-content: center;
  gap: 15px;
  background-color: #82c99c;
  font-size: clamp(1.8rem, 2.2vw, 2.8rem);
}

.introText {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 13px;
  text-transform: uppercase;
  letter-spacing: 6px;
  font-weight: bold;
}

.introImage {
  min-height: 3rem;
  min-width: 3rem;
}

.spin {
  display: grid;
  place-items: center;
  animation: spin-animation 1.5s 1;
  cursor: pointer;
}

.spin:hover {
  animation-iteration-count: infinite;
}

.toolTipCat {
  position: absolute;
  transform: rotate(30deg);
  margin-left: -100%;
  cursor: pointer;
  z-index: 1;
  transition: transform 0.5s;
}

.toolTipCat:hover {
  transform: translateX(14%) rotate(30deg);
}

.toolTipText {
  visibility: hidden;
  width: 130px;
  background-color: black;
  color: #fff;
  font-size: 1rem;
  text-align: center;
  border-radius: 5px;
  padding: 5px 2px;
  position: absolute;
  top: -15%;
  left: 70%;
  transform: rotate(-30deg);
}

.toolTipText::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 100%;
  margin-top: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent black transparent transparent;
}

.toolTipCat:hover .toolTipText {
  visibility: visible;
}

@keyframes spin-animation {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@media (width < 600px) {
  .cat {
    margin-top: 9%;
  }
}

@media (width < 500px) {
  .intro {
    padding: 10px;
  }

  .introImage {
    min-height: 2rem;
    min-width: 2rem;
  }

  .introText {
    font-size: 1.5rem;
  }
}

@media (width < 420px) {
  .introText {
    font-size: 1.4rem;
  }
}

@media (width < 400px) {
  .introText {
    font-size: 1.2rem;
  }
}

@media (width < 350px) {
  .introText {
    font-size: 1.1rem;
  }
}
